import classNames from "classnames";
import { useState } from "react";
import { AnimatePresence } from "motion/react";
import * as motion from "motion/react-client";

import type { WpGraphProject } from "~/types";

import { slugify } from "~/lib/utils";
import ProjectDetail from "~/components/widgets/ProjectDetail";

export default function ProjectList({
    projects,
}: {
    projects: Array<WpGraphProject>;
}) {
    const [selectedProject, setSelectedProject] = useState(
        projects[0].id || null,
    );

    const projectsObj = Object.fromEntries(
        projects.map((project) => [project.id, project]),
    );

    return (
        <>
            <div className="md:flex md:gap-9 lg:gap-14 xl:gap-20">
                <ul className="mt-4 md:mt-0 md:w-[46%] lg:relative">
                    {projects.map((project, indexItem) => (
                        <motion.li key={project.id} initial={false}>
                            <div className="border-b border-black">
                                <div
                                    className={classNames(
                                        "relative z-10 flex cursor-pointer flex-wrap items-end gap-x-3 overflow-hidden bg-white py-2 lg:py-3.5",
                                        "group",
                                        "after:absolute after:-top-full after:-z-10 after:h-full after:w-full after:bg-black",
                                        "after:transition-[top] after:duration-[0.45s] after:ease-[cubic-bezier(0.19,1,0.22,1)]", // After Transition
                                        "hover:after:top-0 aria-expanded:after:top-0", // After animated properties
                                    )}
                                    role="button"
                                    aria-controls={slugify(project.title)}
                                    aria-expanded={
                                        selectedProject &&
                                        selectedProject === project.id
                                            ? "true"
                                            : "false"
                                    }
                                    data-ta-toggle-project-detail
                                    onClick={() => {
                                        selectedProject !== project.id
                                            ? setSelectedProject(project.id)
                                            : setSelectedProject(null);
                                    }}
                                >
                                    <div className="relative w-full">
                                        <span
                                            className={classNames(
                                                "flex flex-wrap items-end",
                                                "group-hover:-translate-y-[100%]",
                                                "group-aria-expanded:-translate-y-[100%]",
                                                "transition-transform duration-500 ease-[cubic-bezier(0.19,1,0.22,1)]",
                                            )}
                                        >
                                            <span className="sm:text-[30px]] pr-2 font-vvds-fifties-scond text-[25px] uppercase leading-[87%] tracking-[0.01em]">
                                                {project.title}
                                            </span>
                                            <span className="text-xs font-medium">
                                                {project.job}
                                            </span>
                                        </span>
                                        <span
                                            className={classNames(
                                                "flex flex-wrap items-end",
                                                "text-black",
                                                "absolute",
                                                "translate-y-full",
                                                "group-hover:-translate-y-[100%]",
                                                "group-aria-expanded:-translate-y-[100%]",
                                                "group-hover:pl-1 group-hover:md:pl-2",
                                                "group-aria-expanded:pl-1 group-aria-expanded:md:pl-2",
                                                "group-hover:text-white",
                                                "group-aria-expanded:text-white",
                                                "transition-all duration-500 ease-[cubic-bezier(0.19,1,0.22,1)]",
                                            )}
                                            aria-hidden={true}
                                        >
                                            <span className="sm:text-[30px]] pr-2 font-vvds-fifties-scond text-[25px] uppercase leading-[87%] tracking-[0.01em]">
                                                {project.title}
                                            </span>
                                            <span className="text-xs font-medium">
                                                {project.job}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <AnimatePresence mode="wait" initial={false}>
                                    {selectedProject && (
                                        <motion.div
                                            key={`mobil-detail-${project.id}`}
                                            initial={{
                                                height:
                                                    selectedProject ===
                                                    project.id
                                                        ? 0
                                                        : "auto",
                                                opacity:
                                                    selectedProject ===
                                                    project.id
                                                        ? 0
                                                        : 1,
                                            }}
                                            animate={{
                                                height:
                                                    selectedProject ===
                                                    project.id
                                                        ? "auto"
                                                        : 0,
                                                opacity:
                                                    selectedProject ===
                                                    project.id
                                                        ? 1
                                                        : 0,
                                            }}
                                            exit={{
                                                opacity:
                                                    selectedProject ===
                                                    project.id
                                                        ? 0
                                                        : 1,
                                                height:
                                                    selectedProject ===
                                                    project.id
                                                        ? 0
                                                        : "auto",
                                            }}
                                            className="overflow-hidden md:hidden"
                                        >
                                            <ProjectDetail project={project} />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </motion.li>
                    ))}
                </ul>
                <div className="hidden flex-1 md:block">
                    <AnimatePresence mode="wait">
                        {selectedProject && (
                            <motion.div
                                key={
                                    selectedProject
                                        ? projectsObj[selectedProject].id
                                        : "empty"
                                }
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <ProjectDetail
                                    project={projectsObj[selectedProject]}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </>
    );
}
