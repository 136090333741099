import type { WpGraphProject } from "~/types";
import ProjectGallery from "./ProjectGallery";

export default function ProjectDetail({
    project: { thumbnail, title, url, job, excerpt, description, gallery },
}: {
    project: WpGraphProject;
}) {
    return (
        <div className="py-3 md:pr-4 md:pt-0">
            {thumbnail && (
                <>
                    {gallery ? (
                        <ProjectGallery
                            thumbnail={thumbnail.node}
                            gallery={gallery}
                        />
                    ) : (
                        <div className="">
                            <img
                                src={thumbnail.node.mediumUrl}
                                alt={"title"}
                                loading="lazy"
                                className="w-full object-cover pb-4"
                            />
                        </div>
                    )}
                </>
            )}
            <p className="mb-2 flex flex-wrap items-end gap-3 lg:mb-4">
                <span className="font-vvds-fifties-scond text-[25px]/[87%] uppercase tracking-[0.01em]">
                    {title}
                </span>
                <span className="text-xs font-medium">{job}</span>
            </p>
            {excerpt && (
                <div
                    className="space-y-2 text-[11px]/[15px] font-medium tracking-[0.07em] lg:space-y-4 lg:pr-3"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            )}
            <div className="mt-2 text-[11px]/[15px] tracking-[0.07em]">
                {url && (
                    <a href={url} className="font-bold">
                        {url}
                    </a>
                )}
            </div>
        </div>
    );
}
