import type { WpGraphMediaItem } from "~/types";

import { AnimatePresence } from "motion/react";
import * as motion from "motion/react-client";
import { useState } from "react";
import { wrap } from "popmotion";
import classNames from "classnames";

import RightArrow from "../icons/RightArrow";
import LeftArrow from "../icons/LeftArrow";

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 500 : -500,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 500 : -500,
            opacity: 0,
        };
    },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

export default function ProjectGallery({
    thumbnail,
    gallery,
}: {
    thumbnail: WpGraphMediaItem;
    gallery?: { nodes: Array<WpGraphMediaItem> };
}) {
    if (!thumbnail) return null;

    const images = [thumbnail, ...(gallery?.nodes || [])];

    const [[selectedThumbIndex, direction], setSelectedThumbIndex] = useState([
        0, 0,
    ]);

    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    const imageIndex = wrap(0, images.length, selectedThumbIndex);

    const paginate = (newDirection: number) => {
        setSelectedThumbIndex([
            selectedThumbIndex + newDirection,
            newDirection,
        ]);
    };

    // const arrowAction = (direction: "left" | "right") => {
    //     if (direction === "left") {
    //         setSelectedThumbIndex((prevIndex) =>
    //             prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    //         );
    //     } else {
    //         setSelectedThumbIndex((prevIndex) =>
    //             prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    //         );
    //     }
    // };

    return (
        <div className="relative mb-4 flex flex-col md:gap-3 lg:gap-4">
            <div className="absolute inset-x-0 bottom-0 z-10 flex justify-between p-4 md:hidden">
                <button
                    type="button"
                    className="size-10 rounded-full bg-black p-2 text-white shadow-sm transition-colors hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                    onClick={() => paginate(-1)}
                >
                    <LeftArrow size="size-6" />
                </button>
                <button
                    type="button"
                    className="size-10 rounded-full bg-black p-2 text-white shadow-sm transition-colors hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                    onClick={() => paginate(1)}
                >
                    <RightArrow size="size-6" />
                </button>
            </div>
            <div className="aspect-w-16 aspect-h-9">
                <AnimatePresence mode="wait">
                    {images[imageIndex] && (
                        <motion.div
                            key={images[imageIndex].id}
                            initial={{ x: 300, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{ x: -300, opacity: 0 }}
                            // custom={direction}
                            // variants={variants}
                            // initial="enter"
                            // animate="center"
                            // exit="exit"
                            // transition={{
                            //     x: {
                            //         type: "spring",
                            //         stiffness: 300,
                            //         damping: 30,
                            //     },
                            //     opacity: { duration: 0.2 },
                            // }}
                        >
                            <img
                                alt={images[imageIndex].altText}
                                src={images[imageIndex].astroImg?.src}
                                loading="lazy"
                                sizes={
                                    images[imageIndex].astroImg?.options
                                        .sizes || ""
                                }
                                srcSet={
                                    images[imageIndex].astroImg?.srcSet
                                        .attribute || ""
                                }
                                className="aspect-square w-full object-cover lg:aspect-square lg:object-center"
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <div className="hidden md:block md:max-w-none md:shrink-0">
                {/* Thumb */}
                <div className="-mx-2 md:flex">
                    <AnimatePresence>
                        {images.map((image, index) => (
                            <motion.div
                                key={`thumb-${image.id}`}
                                onClick={() =>
                                    setSelectedThumbIndex([index, direction])
                                }
                                className={classNames(
                                    "relative flex cursor-pointer items-center justify-center",
                                    "w-1/3",
                                )}
                                initial={{
                                    width:
                                        selectedThumbIndex === index
                                            ? "33.333333%"
                                            : "0",
                                }}
                                animate={{
                                    width:
                                        selectedThumbIndex === index
                                            ? "0"
                                            : "33.333333%",
                                }}
                                exit={{
                                    width:
                                        selectedThumbIndex === index
                                            ? "33.333333%"
                                            : "0",
                                }}
                            >
                                {/* <span className="sr-only">
                                            {image.title}
                                        </span>
                                        <span className="relative inset-0 aspect-square overflow-hidden"> */}
                                <img
                                    alt=""
                                    src={image.thumbUrl}
                                    loading="lazy"
                                    className="size-full object-cover md:px-2"
                                />
                                {/* </span>
                                        <span
                                            aria-hidden="true"
                                            className="pointer-events-none absolute inset-0 ring-2 ring-transparent ring-offset-1 group-data-[selected]:ring-indigo-500"
                                        /> */}
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}
